import React, { useEffect, useState } from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { Text } from '@atoms/Typography'
import Toast from '../atoms/Toast'

const Cookies = () => {
  if (typeof window === 'undefined' || typeof localStorage === 'undefined') {
    return null
  }

  const [scrolled, setScrolled] = useState(false)
  const [cookiesAccepted, setCookiesAccepted] = useState(!!localStorage.getItem('cookies-accepted'))

  const handleScroll = () => {
    let lastScrollTop = 0
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    if (scrollTop > lastScrollTop && !scrolled) {
      setScrolled(true)

      localStorage.setItem('cookies-accepted', true);
      setCookiesAccepted(true)

      window.removeEventListener('scroll', handleScroll)
    }
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <Toast visible={!scrolled && !cookiesAccepted}>
      <S.Content>
        This website uses
        <span> cookies</span>
        . By continuing to use our site you accept the use
        of these cookies.
      </S.Content>
    </Toast>
  )
}

const S = {}

S.Content = styled(Text)`
  ${breakpoints({
    xs: css`
      > span {
        color: ${th('colors.yellow')};
        font-weight: ${th.fontWeight('medium')};
      }

      font-size: 13;
      line-height: 1.4;
      font-weight: ${th.fontWeight('normal')};
    `,
  })}
`

export default Cookies
