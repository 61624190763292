// gatsby-browser.js
import React from 'react'
import { ThemeProvider } from '@xstyled/styled-components'
import GlobalStyle from './src/assets/styles'
import Cookies from './src/components/organisms/Cookies'

import theme from './src/theme'

export const wrapPageElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        {element}
        <Cookies />
      </>
    </ThemeProvider>
  )
}
