import { rpxTransformers } from '@xstyled/system'

const theme = {
  transformers: {
    ...rpxTransformers,
  },
  fonts: [
    "Graphik, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  ],
  screens: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1320,
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 600,
    black: 700,
  },
  colors: {
    white: '#fff',
    dark: '#120F20',
    light: '#fff',
    pink: '#FF9191',
    yellow: '#FFF100',
    green: '#84EE82',
    grey: 'rgba(0, 0, 0, 0.15)',
    grey900: '#25232F',
    greyInvert: 'rgba(255, 255, 255, 0.15)',
  },
  zIndices: {
    menu: 8,
    over: 9,
  },
}

export default theme
