import React from 'react'
import { Link } from 'gatsby'
import styled, { x, breakpoints, css } from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import { ifProp } from 'styled-tools'

import arrowRight from '@assets/svg/arrow-right.svg'

export const H1 = styled.h1Box(
  breakpoints({
    xs: css`
      letter-spacing: -0.02em;
      font-weight: ${th('fontWeights.medium')};
      font-size: 30;
      line-height: 140%;
    `,
    md: css`
      letter-spacing: -0.02em;
      font-weight: ${th('fontWeights.medium')};
      font-size: 42;
      line-height: 140%;
    `,
    lg: css`
      font-size: 56;
    `,
  })
)

export const H2 = styled.h2Box(
  breakpoints({
    xs: css`
      letter-spacing: -0.03em;
      line-height: 120%;
      font-weight: ${th('fontWeights.medium')};
      font-size: 28;
    `,
    md: css`
      font-size: 42;
    `,
    xl: css`
      font-size: 48;
      line-height: 130%;
    `,
  })
)

export const H3 = styled.h3Box(
  breakpoints({
    xs: css`
      letter-spacing: -0.02em;
      font-weight: ${th('fontWeights.medium')};
      font-size: 24;
      line-height: 120%;
      margin-bottom: 10;
    `,
    md: css`
      font-size: 28;
    `,
    xl: css`
      font-size: 40;
    `,
  })
)

export const H4 = styled.h4Box`
  font-weight: ${th('fontWeights.medium')};
  line-height: 1.4;
  letter-spacing: -0.02em;
  ${breakpoints({
    xs: css`
      font-size: 20;
      line-height: 26px;
    `,
    md: css`
      font-size: 22;
      line-height: 28.6px;
    `,
    xl: css`
      font-size: 24;
      line-height: 33.6px;
    `,
  })}
`

export const TitleSection = styled.h2Box`
  font-weight: ${th('fontWeights.medium')};
  ${breakpoints({
    xs: css`
      letter-spacing: -0.02em;
      font-size: 22;
      margin-bottom: 50;
    `,
    lg: css`
      font-size: 36;
      margin-bottom: 90;
    `,
  })}
`

export const TitleContent = styled.h2Box(
  breakpoints({
    xs: css`
      font-weight: ${th('fontWeights.medium')};
      font-size: 20;
      letter-spacing: -0.02em;
      line-height: 1.4;
      margin-bottom: 25;
    `,
    lg: css`
      font-size: 32;
      margin-bottom: 30;
    `,
  })
)

export const TitleCard = styled.h3Box(
  breakpoints({
    xs: css`
      font-weight: ${th('fontWeights.medium')};
      font-size: 24;
      letter-spacing: -0.02em;
      line-height: 1.4;
      margin-bottom: 10;
    `,
    lg: css`
      font-size: 32;
      margin-bottom: 20;
    `,
  })
)

export const Text = styled.pBox`
  line-height: 1.5;
  font-size: 18;
  ${breakpoints({
    lg: css`
      font-size: 20;
    `,
  })}
`

const ArrowRightIcon = styled(arrowRight)`
  width: 27;
  height: 16;
  margin-right: 10;
`

export const TextLink = styled(
  ({ invertColor, internal, arrowRight, ...props }) =>
    internal ? (
      <Link {...props}>
        <span>{props.children}</span>
        {arrowRight && <ArrowRightIcon />}
      </Link>
    ) : (
      <x.div as="a" {...props}>
        <span>{props.children}</span>
        {arrowRight && <ArrowRightIcon />}
      </x.div>
    )
)`
  transition: all 0.3s ease;
  & span {
    transition: all 0.3s ease;
    padding-bottom: 4px;
    border-bottom: 1px solid
      ${({ invertColor }) =>
        invertColor ? th('colors.greyInvert') : th('colors.grey')};

    ${ifProp(
      'noUnderline',
      css`
        border-bottom: none;
      `
    )}
  }
  &:hover {
    span {
      border-color: ${({ invertColor }) =>
        invertColor ? th('colors.light') : th('colors.dark')};
    }
    cursor: pointer;
  }
`
