import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { transparentize } from 'polished'

const Toast = ({ visible, ...props }) => (
  <S.Toast {...props} visible={visible} />
)

const S = {}

S.Toast = styled.div`
  ${breakpoints({
    xs: css`
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 10;
      height: 56;
      z-index: ${th('zIndices.over')};
      background: ${({ theme }) => transparentize(0.2, theme.colors.dark)};
      color: ${th('colors.light')};
      transition: 0.1ms;
      ${({ visible }) => css`
        visibility: ${visible ? 'visible' : 'hidden'};
        opacity: ${visible ? '1' : '0'};
      `}
    `,
    md: css`
      bottom: 10;
      left: unset;
      right: 10;
      max-width: 375;
      border-radius: 3;
      display: flex;
      align-items: center;
    `,
  })}
`

export default Toast
