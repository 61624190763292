import { createGlobalStyle, breakpoints, css } from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import reset from 'styled-reset-advanced'
import 'prismjs/themes/prism-okaidia.css'

import GraphikRegular from './fonts/GraphikLCWeb-Regular.woff'
import GraphikRegular2 from './fonts/GraphikLCWeb-Regular.woff2'

import GraphikRegularItalic from './fonts/GraphikLCWeb-RegularItalic.woff'
import GraphikRegularItalic2 from './fonts/GraphikLCWeb-RegularItalic.woff2'

import GraphikMedium from './fonts/GraphikLCWeb-Medium.woff'
import GraphikMedium2 from './fonts/GraphikLCWeb-Medium.woff2'

import GraphikSemiBold from './fonts/GraphikWeb-Semibold.woff'
import GraphikSemiBold2 from './fonts/GraphikWeb-Semibold.woff2'

const GlobalStyle = createGlobalStyle`
  ${reset};

  [data-animation]{
    opacity: 0
  }



  @font-face {
    font-family: 'Graphik';
    src: local('Graphik LC Web Regular'), local('GraphikLCWeb-Regular'),
      url(${GraphikRegular2}) format('woff2'),
      url(${GraphikRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Graphik';
    src: local('Graphik LC Web Regular Italic'), local('GraphikLCWeb-RegularItalic'),
      url(${GraphikRegularItalic2}) format('woff2'),
      url(${GraphikRegularItalic}) format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Graphik';
    src: local('Graphik LC Web Medium'), local('GraphikLCWeb-Medium'),
      url(${GraphikMedium2}) format('woff2'),
      url(${GraphikMedium}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Graphik';
    src: local('GraphikLCWeb-SemiBold'),
      url(${GraphikSemiBold2}) format('woff2'),
      url(${GraphikSemiBold}) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: ${th('fonts.0')};
    line-height: 1.5;
    color: ${th('colors.dark')};
    ${breakpoints({
      xs: css`
        font-size: 18;
      `,
      sm: css`
        font-size: 20;
      `,
    })}
  }

  b, strong {
    font-weight: ${th('fontWeights.bold')};
  }

  em {
    font-style: italic;
  }

  img, video {
    max-width: 100%;
  }

  :not(pre) > code[class*="language-"], pre[class*="language-"] {
    background: ${th('colors.dark')};
  }
`

export const preloadFonts = [GraphikRegular2, GraphikMedium2, GraphikSemiBold2]

export default GlobalStyle
